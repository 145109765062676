@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply font-ged
}
#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.banner {
  @apply max-w-6xl mx-auto px-2
}
.header {
  @apply fixed w-full top-0 left-0 bg-transparent text-gray-800 text-sm shadow-md font-bold
}

.headerScroll {
  @apply bg-[#22458d] overflow-hidden transition ease-in-out duration-500 text-white
}

.mobilemenu {
  @apply bg-[#22458d] text-white px-6 py-20 md:hidden h-screen w-full text-center text-xl
}

.title {
  @apply text-5xl pb-3
}

.substitle {
  @apply text-3xl font-light  text-gray-700
}

.trait {
  @apply w-16 h-1 bg-[#b5252d]
}

.substitle-blanc {
  @apply text-3xl font-light  text-white
}

.trait-blanc {
  @apply w-16 h-1 bg-white
}

.para {
  @apply leading-6 text-gray-700 text-lg
}

.para-blanc {
  @apply leading-6 text-white
}

.bleu {
  @apply text-[#22458d] font-bold
}

.rouge {
  @apply text-[#b5252d] font-bold
}

.btn {
  @apply bg-[#b5252d] rounded-md text-white p-2 hover:bg-red-800 transition-all duration-500
}

.btn-blanc {
  @apply border-2 border-white rounded-md text-white p-2 hover:bg-white hover:text-gray-700 
  transition ease-in-out duration-500 mb-4
}

.socials {
  @apply py-3 text-2xl text-gray-500 space-x-6
}

.footer-title {
  @apply text-xl font-light  text-gray-500
}

